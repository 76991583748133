import Box from "@mui/material/Box";

export default function Docs() {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      Documents
    </Box>
  );
}
